import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Navbar, Nav } from 'react-bootstrap';
import { GetReliefPitcherGameLogData, GetReliefPitcherReportData, GetReliefPitcherReportLeaderboardsData } from '../../Services/ApiSvc';
import DatePicker from '../Common/DatePicker';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import RadioButton from '../Common/RadioButton';
import StatFilter, { convertFilterToNote, createFilter } from '../Common/StatFilter';
import TabulatorTable from '../Common/TabulatorTable';
import { customDateString } from '../Common/CommonFunctions';
import GameLog from '../GameLog/GameLog';
import { statFunctions } from '../Common/StatFunctions';

function ReliefPitcherReport(props) {
    const [aggregatedData, setAggregatedData] = useState(null);
    const [columnGroups, setColumnGroups] = useState(null);
    const [data, setData] = useState({
        exceptionMsg: null,
        gameLogs: null,
        loading: false,
        matrix: null,
        playerId: null,
    });
    const [formOptions, setFormOptions] = useState(null);
    const [excelNotes, setExcelNotes] = useState(null);
    const [leaderboardsData, setLeaderboardsData] = useState(null);
    const [summaryTableDateRange, setSummaryTableDateRange] = useState(null);
    const [summaryTableSituation, setSummaryTableSituation] = useState({
        inningOfEntry: 'Total',
        runDifferential: 'Total',
    });
    const [reliefPitcherTab, setReliefPitcherTab] = useState('matrix');

    useEffect(() => {
        if (props.groups.length < 1) {
            return;
        }

        let colGroups = {
            displayStats: [],
            filters: [],
            gameLog: [],
            summaryTables: {
                reliefPitcherSituation: [],
                reliefPitcherSaves: [],
                reliefPitcherHolds: [],
                reliefPitcherGeneral: [],
                reliefPitcherComponentStats: [],
            },
        };

        props.groups.find(group => group.id === 65).columns.forEach(column => {
            if (column.formInputs.isISWFilter || column.id === 69)
                colGroups.filters.push(Object.assign({}, column));
            else
                colGroups.displayStats.push(Object.assign({}, column));
        })

        colGroups.summaryTables.reliefPitcherSituation =
            props.groups.find(group => group.id === 73).columns.slice();
        colGroups.summaryTables.reliefPitcherSaves =
            props.groups.find(group => group.id === 74).columns.slice();
        colGroups.summaryTables.reliefPitcherHolds =
            props.groups.find(group => group.id === 75).columns.slice();
        colGroups.summaryTables.reliefPitcherGeneral =
            props.groups.find(group => group.id === 76).columns.slice();
        colGroups.summaryTables.reliefPitcherComponentStats =
            props.groups.find(group => group.id === 77).columns.slice();
        colGroups.summaryTables.reliefPitcherComponentStats.push({ title: 'FIPConstant', field: 'FIPConstant' });

        colGroups.gameLog = props.groups.find(group => group.id === 70).columns.slice();
        colGroups.gameLog.unshift({ title: 'Opponent', field: 'OppTeamAbbr', width: 70 });
        colGroups.gameLog.unshift({ title: 'Game Date', field: 'GameDateString', width: 100 });

        setColumnGroups(colGroups);
    }, [])

    useEffect(() => {
        if (!data?.gameLogData)
            return;

        let statNames = [... new Set(
            columnGroups.summaryTables.reliefPitcherSituation.map(column => column.field)
            .concat(columnGroups.summaryTables.reliefPitcherSaves.map(column => column.field))
            .concat(columnGroups.summaryTables.reliefPitcherHolds.map(column => column.field))
            .concat(columnGroups.summaryTables.reliefPitcherGeneral.map(column => column.field))
            .concat(columnGroups.summaryTables.reliefPitcherComponentStats.map(column => column.field))
            .concat(columnGroups.gameLog.map(column => column.field))
        )];

        setAggregatedData(statFunctions.aggregateData(
            Object.values(data.gameLogData).flatMap(ele => ele),
            (game) => {
                return game.Inning
                    && game.GameDate >= summaryTableDateRange.startDate
                    && game.GameDate <= summaryTableDateRange.endDate;
            },
            statNames
        ));
    }, [summaryTableDateRange])

    useEffect(() => {
        if (!data?.gameLogData || Object.keys(data.gameLogData).length < 1
            || reliefPitcherTab !== 'matrix' || !summaryTableSituation)
            return;

        let statNames = [... new Set(
            columnGroups.summaryTables.reliefPitcherSituation.map(column => column.field)
                .concat(columnGroups.summaryTables.reliefPitcherSaves.map(column => column.field))
                .concat(columnGroups.summaryTables.reliefPitcherHolds.map(column => column.field))
                .concat(columnGroups.summaryTables.reliefPitcherGeneral.map(column => column.field))
                .concat(columnGroups.summaryTables.reliefPitcherComponentStats.map(column => column.field))
                .concat(columnGroups.gameLog.map(column => column.field))
        )];

        setAggregatedData(statFunctions.aggregateData(
            Object.values(data.gameLogData).flatMap(ele => ele),
            shouldAggregateGame,
            statNames
        ));

        getLeaderboardsData(summaryTableSituation);

        function shouldAggregateGame(game) {
            return (game.IL && game.DoubleHeader <= 1) || (game.Inning
                && gameMeetsFilterCriteria(game)
                && (
                    summaryTableSituation.inningOfEntry === 'Total'
                    || summaryTableSituation.inningOfEntry === game.Inning
                    || (summaryTableSituation.inningOfEntry === 10 && game.Inning > 10)
                ) && (
                    summaryTableSituation.runDifferential === 'Total'
                    || summaryTableSituation.runDifferential === game['Run Differential Before']
                    || (summaryTableSituation.runDifferential === -4 && game['Run Differential Before'] < -4)
                    || (summaryTableSituation.runDifferential === 4 && game['Run Differential Before'] > 4)
                ))
        }
    }, [summaryTableSituation, reliefPitcherTab, data])

    useEffect(() => {
        if (!formOptions)
            return;

        updateNotes();
        getData();
    }, [formOptions])

    function gameMeetsFilterCriteria(game) {
        let meetsCriteria = true;
        formOptions.filters.forEach(filter => {
            if (filter.value == null)
                return;

            switch (filter.statId) {
                case 69:
                    meetsCriteria = meetsCriteria && (
                        filter.value.upperBound == null || filter.value.upperBound >= game.gmLI
                    ) && (
                            filter.value.lowerBound == null || filter.value.lowerBound <= game.gmLI
                        )
                    break;
                case 593:
                    meetsCriteria = meetsCriteria && filter.value && filter.value.split(',').includes(game['Baserunners Before'].toString());
                    break;
                case 601:
                    meetsCriteria = meetsCriteria && (
                        filter.value.upperBound == null || filter.value.upperBound >= game.PitcherDaysRest
                    ) && (
                            filter.value.lowerBound == null || filter.value.lowerBound <= game.PitcherDaysRest
                        );
                    break;
                case 607:
                    meetsCriteria = meetsCriteria && (
                        filter.value.upperBound == null || filter.value.upperBound >= game['Outs Before']
                    ) && (
                            filter.value.lowerBound == null || filter.value.lowerBound <= game['Outs Before']
                        );
                    break;
                default:
                    break;
            }
        });
        return meetsCriteria;
    }

    async function getData() {
        setData({
            exceptionMsg: null,
            gameLogs: null,
            loading: true,
            matrix: null,
            playerId: null,
        });
        setAggregatedData(null);

        const gameLogsTask = formOptions.mode === 'single'
            ? GetReliefPitcherGameLogData(props.player.id)
            : Promise.resolve([]);
        const reliefPitcherReportTask = GetReliefPitcherReportData(formOptions);

        let responses = await Promise.all([gameLogsTask, reliefPitcherReportTask]);

        let gameLogs = {};
        let minSeasonDate = new Date(Number(formOptions.season), 11, 31);
        let maxSeasonDate = new Date(Number(formOptions.season), 0, 1);
        responses[0].forEach(game => {
            if (game.Season.toString() !== formOptions.season)
                return;

            let gameDateString = customDateString(game.GameDate);
            if (!Object.hasOwn(gameLogs, gameDateString))
                gameLogs[gameDateString] = [];

            gameLogs[gameDateString].push(game);

            if (game.GameDate < minSeasonDate)
                minSeasonDate = game.GameDate;
            if (game.GameDate > maxSeasonDate)
                maxSeasonDate = game.GameDate;
        })

        setSummaryTableDateRange({
            startDate: minSeasonDate,
            endDate: maxSeasonDate,
            dateToUpdate: 'start',
        });
        setData({
            exceptionMsg: responses[1].exceptionMsg,
            gameLogData: gameLogs,
            loading: false,
            matrix: responses[1].rows,
            playerId: props.player.id,
        });
    }

    async function getLeaderboardsData(situation) {
        setLeaderboardsData({
            columns: null,
            data: null,
            exceptionMsg: null,
            initSort: null,
            loading: true,
        });

        const response = await GetReliefPitcherReportLeaderboardsData(formOptions, situation);

        let displayStat = formOptions.displayStatId === 736
            ? 'Appearances'
            : columnGroups.displayStats.find(stat => stat.id === formOptions.displayStatId).title;

        let leaderboardsColumns = [
            {
                field: 'rank',
                title: 'Rank',
                width: 50,
            },
            {
                field: 'player',
                hozAlign: 'left',
                title: 'Player',
                width: 150,
            },
            {
                field: 'stat',
                sorter: 'number',
                title: displayStat,
                width: 100,
            },
        ];
        let leaderboardsInitSort = [{ column: 'stat', dir: 'desc' }];

        setLeaderboardsData({
            columns: leaderboardsColumns,
            data: response.data,
            exceptionMsg: response.exceptionMsg,
            initSort: leaderboardsInitSort,
            loading: false,
        });
    }

    function updateNotes() {
        let notes = {
            formNotes: [],
        };

        if (formOptions) {
            notes.formNotes.push(`Player: ${props.player.name}`);
            switch (formOptions.mode) {
                case 'single':
                    notes.formNotes.push(`Season = ${formOptions.season}`);
                    break;
                case 'multiple':
                    notes.formNotes.push(`From: ${formOptions.startSeason}`);
                    notes.formNotes.push(`To: ${formOptions.endSeason}`);
                    break;
                case 'date':
                    notes.formNotes.push(`From: ${formOptions.startDate}`);
                    notes.formNotes.push(`To: ${formOptions.endDate}`);
                    break;
            }
            formOptions.filters.forEach(filter => {
                if (filter.value) {
                    let filterNote = convertFilterToNote(filter);
                    if (filterNote)
                        notes.formNotes.push(filterNote);
                }
            })

            notes.formNotes.push('Display Stat: ' +
                columnGroups.displayStats.find(stat => stat.id === formOptions.displayStatId).formInputs.formLabel
            );
        }

        setExcelNotes(notes);
    }

    function updateSummaryTableDateRange(date, dateToUpdate) {
        let newDateRange = Object.assign({}, summaryTableDateRange);

        dateToUpdate = getDateToUpdate(dateToUpdate, newDateRange);

        if (dateToUpdate === 'start') {
            newDateRange.startDate = date;
            newDateRange.dateToUpdate = 'end';
        } else if (dateToUpdate === 'end') {
            newDateRange.endDate = date;
            newDateRange.dateToUpdate = 'start';
        } else if (newDateRange.dateToUpdate === 'start' && newDateRange.endDate >= date) {
            newDateRange.startDate = date;
            newDateRange.dateToUpdate = 'end';
        } else if (newDateRange.dateToUpdate === 'end' && newDateRange.startDate <= date) {
            newDateRange.endDate = date;
            newDateRange.dateToUpdate = 'start';
        }

        setSummaryTableDateRange(newDateRange);

        function getDateToUpdate(dateToUpdateOverride, dateRangeObj) {
            if (dateToUpdateOverride)
                return dateToUpdateOverride;

            if ((newDateRange.dateToUpdate === 'start' || newDateRange.startDate >= date) && newDateRange.endDate >= date)
                return 'start';

            return 'end';
        }
    }

    let matrixColumns = [
        { title: '', field: 'label', width: 50 },
        { title: '<=-4', field: 'BattingTeamLeadsby4', width: 50, cellClick: getSituationOnClickFunction(-4, setSummaryTableSituation) },
        { title: '-3', field: 'BattingTeamLeadsby3', width: 50, cellClick: getSituationOnClickFunction(-3, setSummaryTableSituation) },
        { title: '-2', field: 'BattingTeamLeadsby2', width: 50, cellClick: getSituationOnClickFunction(-2, setSummaryTableSituation) },
        { title: '-1', field: 'BattingTeamLeadsby1', width: 50, cellClick: getSituationOnClickFunction(-1, setSummaryTableSituation) },
        { title: '0', field: 'TieGame', width: 50, cellClick: getSituationOnClickFunction(0, setSummaryTableSituation) },
        { title: '1', field: 'PitchingTeamLeadsby1', width: 50, cellClick: getSituationOnClickFunction(1, setSummaryTableSituation) },
        { title: '2', field: 'PitchingTeamLeadsby2', width: 50, cellClick: getSituationOnClickFunction(2, setSummaryTableSituation) },
        { title: '3', field: 'PitchingTeamLeadsby3', width: 50, cellClick: getSituationOnClickFunction(3, setSummaryTableSituation) },
        { title: '>=4', field: 'PitchingTeamLeadsby4', width: 50, cellClick: getSituationOnClickFunction(4, setSummaryTableSituation) },
        { title: 'Total', field: 'Total', width: 50, cellClick: getSituationOnClickFunction('Total', setSummaryTableSituation) },
    ];

    return (
        <React.Fragment>
            <Navbar bg='light' variant='light' className='playerNav pdfChunk pdfIgnore'>
                <Nav>
                    <Nav.Link
                        className={reliefPitcherTab === 'matrix' ? 'nav-selected' : null}
                        href='#matrix'
                        onClick={() => setReliefPitcherTab('matrix')}
                    >
                        {
                            reliefPitcherTab === 'matrix' &&
                            <span style={{ transform: 'translateX(52px) rotate(135deg)' }}></span>
                        }
                        Appearance Matrix
                    </Nav.Link>
                    <Nav.Link
                        className={reliefPitcherTab === 'gameLog' ? 'nav-selected' : null}
                        href='#gameLog'
                        onClick={() => setReliefPitcherTab('gameLog')}
                    >
                        {
                            reliefPitcherTab === 'gameLog' &&
                            <span style={{ transform: 'translateX(34px) rotate(135deg)' }}></span>
                        }
                        Game Logs
                    </Nav.Link>
                </Nav>
            </Navbar>
            <br />

            <div className='pdfChunk'>
                <h3>Relief Pitcher {reliefPitcherTab === 'matrix' ? 'Appearance Matrix' : 'Game Logs'}</h3>
                <ReliefPitcherReportForm
                    columnGroups={columnGroups}
                    exporters={
                        data.matrix &&
                        <React.Fragment>
                            <ExcelExporter
                                columns={{
                                    gameLog: columnGroups.gameLog,
                                    gameLogSeasonToDate: columnGroups.gameLog.slice(0, 22),
                                    leaderboards: leaderboardsData?.columns
                                        ? leaderboardsData.columns.filter(col => col.id !== 10000)
                                        : [],
                                    matrix: matrixColumns,
                                    reliefPitcherSituation: columnGroups.summaryTables.reliefPitcherSituation,
                                    reliefPitcherSaves: columnGroups.summaryTables.reliefPitcherSaves,
                                    reliefPitcherHolds: columnGroups.summaryTables.reliefPitcherHolds,
                                    reliefPitcherGeneral: columnGroups.summaryTables.reliefPitcherGeneral,
                                }}
                                data={{
                                    gameLog: Object.values(data?.gameLogData)
                                        .flatMap(row => row)
                                        .filter(game => game.Inning > 0
                                            && game.GameDate >= summaryTableDateRange.startDate
                                            && game.GameDate <= summaryTableDateRange.endDate),
                                    gameLogSeasonToDate: Object.values(data?.gameLogData)
                                        .flatMap(row => row)
                                        .filter(game => game.Inning > 0
                                            && game.GameDate >= summaryTableDateRange.startDate
                                            && game.GameDate <= summaryTableDateRange.endDate)
                                        .map(row => row.seasonToDate),
                                    leaderboards: leaderboardsData?.data,
                                    matrix: data.matrix,
                                    reliefPitcherSituation: aggregatedData ? [aggregatedData] : [],
                                    reliefPitcherSaves: aggregatedData ? [aggregatedData] : [],
                                    reliefPitcherHolds: aggregatedData ? [aggregatedData] : [],
                                    reliefPitcherGeneral: aggregatedData ? [aggregatedData] : [],
                                }}
                                notes={{
                                    gameLog: Object.assign(
                                        {
                                            situationNotes: [getSummaryTableSituation(
                                                reliefPitcherTab,
                                                summaryTableSituation,
                                                summaryTableDateRange
                                            )]
                                        },
                                        excelNotes,
                                    ),
                                    leaderboards: Object.assign(
                                        {
                                            situationNotes: [getSummaryTableSituation(
                                                reliefPitcherTab,
                                                summaryTableSituation,
                                                summaryTableDateRange
                                            )]
                                        },
                                        excelNotes,
                                    ),
                                    matrix: excelNotes,
                                    reliefPitcherSituation: Object.assign({
                                        situationNotes: [getSummaryTableSituation(
                                            reliefPitcherTab,
                                            summaryTableSituation,
                                            summaryTableDateRange
                                        )]
                                    },
                                        excelNotes,
                                    ),
                                    reliefPitcherSaves: Object.assign({
                                        situationNotes: [getSummaryTableSituation(
                                            reliefPitcherTab,
                                            summaryTableSituation,
                                            summaryTableDateRange
                                        )]
                                    },
                                        excelNotes,
                                    ),
                                    reliefPitcherHolds: Object.assign({
                                        situationNotes: [getSummaryTableSituation(
                                            reliefPitcherTab,
                                            summaryTableSituation,
                                            summaryTableDateRange
                                        )]
                                    },
                                        excelNotes,
                                    ),
                                    reliefPitcherGeneral: Object.assign({
                                        situationNotes: [getSummaryTableSituation(
                                            reliefPitcherTab,
                                            summaryTableSituation,
                                            summaryTableDateRange
                                        )]
                                    },
                                        excelNotes,
                                    )
                                }}
                                reportName={'ReliefPitcher'}
                                    sheetNames={
                                        (reliefPitcherTab === 'gameLog' ? ['gameLog', 'gameLogSeasonToDate'] : [reliefPitcherTab])
                                            .concat(['reliefPitcherSituation', 'reliefPitcherSaves',
                                                'reliefPitcherHolds', 'reliefPitcherGeneral'])
                                            .concat(reliefPitcherTab === 'matrix' && leaderboardsData
                                                ? ['leaderboards']
                                                : []
                                            )
                                }
                                variant={reliefPitcherTab}
                            />
                            <PDFExporter fileName='ReliefPitcherReport' />
                        </React.Fragment>
                    }
                    player={props.player}
                    submit={setFormOptions}
                    reliefPitcherTab={reliefPitcherTab}
                />
            </div>
            {
                data?.loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                reliefPitcherTab === 'matrix' && data?.matrix && props.player.id === data.playerId &&
                <div className='appearance-matrix pdfChunk'>
                    <br />
                    <h3>Appearance Matrix</h3>
                    <Row style={{ width: '650px' }}>
                        <div style={{
                            marginTop: '26px',
                            height: '268px',
                            width: '80px',
                            backgroundColor: '#e6e6e6',
                            borderLeft: '2px solid black',
                            borderTop: '2px solid black',
                            borderBottom: '2px solid black',
                            verticalAlign: 'middle',
                            padding: '10% 5px'
                        }}>
                            Inning of Entry
                        </div>
                        <div style={{ width: '554px' }}>
                            <div style={{
                                marginLeft: '50px',
                                textAlign: 'center',
                                backgroundColor: '#e6e6e6',
                                borderLeft: '2px solid black',
                                borderTop: '2px solid black',
                                borderRight: '2px solid black'
                            }}>
                                Run Diff. at entry
                            </div>
                            <TabulatorTable
                                cols={matrixColumns}
                                data={data.matrix}
                                selectableRows={false}
                            />
                        </div>
                    </Row>
                    <br />
                </div>
            }
            {
                reliefPitcherTab === 'gameLog' && data?.gameLogData && summaryTableDateRange && props.player.id === data.playerId &&
                <GameLog
                    columns={columnGroups.gameLog}
                    dateRange={summaryTableDateRange}
                    gameLogData={data?.gameLogData}
                    updateDateRange={(date, dateToUpdate) => updateSummaryTableDateRange(date, dateToUpdate)}
                />
            }
            {
                data?.exceptionMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{data.exceptionMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }
            {
                data?.gameLogData && aggregatedData && props.player.id === data.playerId &&
                <div className='pdfChunk'>
                    <br />
                    <h4>
                        Summary
                        {getSummaryTableSituation(reliefPitcherTab, summaryTableSituation, summaryTableDateRange)}
                    </h4>
                    <div style={{
                        textAlign: 'center',
                        width: aggregatedData
                            ? (columnGroups.summaryTables.reliefPitcherSituation.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                            : '100%'
                    }}>
                        <TabulatorTable
                            cols={columnGroups.summaryTables.reliefPitcherSituation}
                            data={[aggregatedData]}
                            selectableRows={false}
                        />
                        <br />
                    </div>
                    <div style={{
                        textAlign: 'center',
                        width: aggregatedData
                            ? (columnGroups.summaryTables.reliefPitcherSaves.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                            : '100%'
                    }}>
                        <TabulatorTable
                            cols={columnGroups.summaryTables.reliefPitcherSaves}
                            data={[aggregatedData]}
                            selectableRows={false}
                        />
                        <br />
                    </div>
                    <div style={{
                        textAlign: 'center',
                        width: aggregatedData
                            ? (columnGroups.summaryTables.reliefPitcherHolds.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                            : '100%'
                    }}>
                        <TabulatorTable
                            cols={columnGroups.summaryTables.reliefPitcherHolds}
                            data={[aggregatedData]}
                            selectableRows={false}
                        />
                        <br />
                    </div>
                    <div style={{
                        textAlign: 'center',
                        width: aggregatedData
                            ? (columnGroups.summaryTables.reliefPitcherGeneral.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                            : '100%'
                    }}>
                        <TabulatorTable
                            cols={columnGroups.summaryTables.reliefPitcherGeneral}
                            data={[aggregatedData]}
                            selectableRows={false}
                        />
                        <br />
                    </div>
                </div>
            }
            {
                !data?.loading && leaderboardsData?.loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                reliefPitcherTab === 'matrix' && data?.matrix && leaderboardsData?.data?.length > 0 && props.player.id === data.playerId &&
                <div className='pdfChunk'>
                    <br />
                    <h5>
                        {props.player.name} Rank:
                        {
                            leaderboardsData.data.find(row => row.playerId === props.player.id)?.rank
                            ?? 'N/A'
                        }
                    </h5>
                    <div style={{
                        textAlign: 'center',
                        width: leaderboardsData
                            ? (leaderboardsData.columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                            : '100%'
                    }}>
                        <TabulatorTable
                            cols={leaderboardsData.columns}
                            data={leaderboardsData.data}
                            initSort={leaderboardsData.initSort}
                            paginationButtonCount={2}
                            paginationSize={25}
                            selectableRows={false}
                        />
                        <br />
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default ReliefPitcherReport;


function ReliefPitcherReportForm(props) {
    let initDate = (new Date(`12/31/${props.player.maxSeason}`)) < new Date()
        ? new Date(`12/31/${props.player.maxSeason}`)
        : new Date();
    const [mode, setMode] = useState('single');
    const [season, setSeason] = useState(props.player.maxSeason.toString());
    const [startSeason, setStartSeason] = useState(props.player.maxSeason.toString());
    const [endSeason, setEndSeason] = useState(props.player.maxSeason.toString());
    const [startDate, setStartDate] = useState(initDate);
    const [endDate, setEndDate] = useState(initDate);
    const [filters, setFilters] = useState([]);
    const [displayStatId, setDisplayStatId] = useState(736) // Pitcher First Entered Game


    let seasonOptions = [];
    for (let i = props.player.maxSeason; i >= props.player.minSeason; i--) {
        seasonOptions.push(
            <option key={i} value={i}>{i}</option>
        )
    };

    useEffect(() => {
        let myFilters = [];

        // Currently, we don't allow filtering on inning or run differential
        props.columnGroups?.filters
            .filter(column => ![604, 614].includes(column.id))
            .forEach((column, filterIndex) => {
                let filter = Object.assign({}, createFilter(filterIndex, column));

                myFilters.push(filter);
            });

        setFilters(myFilters);
    }, [props.columnGroups]);

    useEffect(() => {
        if (props.reliefPitcherTab === 'gameLog')
            setMode('single');
    }, [props.reliefPitcherTab])

    function setFilter(filterIndex, type, value) {
        let newFilters = filters.slice();
        let filterToUpdate = newFilters.find(filter => filter.index === filterIndex)
        filterToUpdate[type] = value;
        setFilters(newFilters);
    }

    function submitForm() {
        let formOptions = {
            displayStatId: displayStatId,
            endDate: endDate,
            endSeason: endSeason,
            filters: filters,
            mode: mode,
            playerId: props.player.id,
            season: season,
            startDate: startDate,
            startSeason: startSeason,
        };

        props.submit(formOptions);
    }

    return (
        <React.Fragment>
            <div className='row'>
                <b className='label'>Mode: </b>
                <Form>
                    <div key={'custom-inline-radio'}>
                        {
                            [
                                { label: 'Single Season', mode: 'single' },
                                { label: 'Multiple Seasons', mode: 'multiple' },
                                { label: 'Date Range', mode: 'date' },
                            ].map((option, index) => {
                                return <RadioButton
                                    checked={mode === option.mode}
                                    disabled={props.reliefPitcherTab === 'gameLog' && option.mode !== 'single'}
                                    id={`custom-inline-radio-${option.mode}`}
                                    inline={true}
                                    label={option.label}
                                    key={index}
                                    name='mode'
                                    onClick={(event) => {
                                        setMode(option.mode)
                                    }}
                                />
                            })
                        }
                    </div>
                </Form>
            </div>
            <br />
            <div className='row'>
                {
                    mode === 'single' &&
                    <React.Fragment>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={season} onChange={(event) => setSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                    </React.Fragment>
                }
                {
                    mode === 'multiple' &&
                    <React.Fragment>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Start Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={startSeason} onChange={(event) => setStartSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>End Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={endSeason} onChange={(event) => setEndSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                    </React.Fragment>
                }
                {
                    mode === 'date' &&
                    <React.Fragment>
                        <b className='label' style={{ paddingRight: '25px' }}>From: </b>
                        <DatePicker
                            maxDate={endDate}
                            minDate={new Date(`1/1/${props.player.minSeason}`)}
                            setDate={(date) => setStartDate(date)}
                            startDate={startDate}
                        />
                        <b className='label' style={{ padding: '0px 25px' }}>To: </b>
                        <DatePicker
                            endDate={endDate}
                            maxDate={new Date(`12/31/${props.player.maxSeason}`)}
                            minDate={startDate}
                            setDate={(date) => setEndDate(date)}
                        />
                    </React.Fragment>
                }
            </div>
            <br />
            {
                props.reliefPitcherTab === 'matrix' &&
                <>
                    <h5>Advanced Filters</h5>
                    <div className='advanced-filter'>
                        {
                            filters.map((filter, filterIndex) =>
                                <StatFilter
                                    filter={filter}
                                    key={filterIndex}
                                    setFilter={setFilter}
                                />
                            )
                        }
                    </div>
                    <br />
                </>
            }
            {
                props.reliefPitcherTab === 'matrix' &&
                <Row>
                    <Col xs={12} lg={1}>
                        <b>Display Stat</b>
                    </Col>
                    {
                        props.columnGroups?.displayStats.map((displayStat, index) => {
                            return <Col xs={6} lg={2}
                                key={index}
                            >
                                <RadioButton
                                    checked={displayStat.id === displayStatId}
                                    disabled={false}
                                    name='displayStat'
                                    label={displayStat.formInputs.formLabel}
                                    onChange={() => setDisplayStatId(displayStat.id)}
                                />
                            </Col>
                        })
                    }
                </Row>
            }
            <br />
            <Row className='pdfIgnore'>
                <Button variant='success'
                    onClick={submitForm}
                >
                    Submit
                </Button>
                <div style={{ margin: '0px 0px 0px auto' }}>
                    {props.exporters}
                </div>
            </Row>
        </React.Fragment>
    )
}

function getSituationOnClickFunction(runDiff, setFunction) {
    return function (e, cell) {
        let innOfEntry = !isNaN(Number(cell.getRow().getData().label.replace('+', '')))
            ? Number(cell.getRow().getData().label.replace('+', ''))
            : 'Total';
        setFunction({ inningOfEntry: innOfEntry, runDifferential: runDiff });
    }
}

function getSummaryTableSituation(tab, situation, dateRange) {
    if (tab === 'matrix' && situation) {
        let runDiff = situation.runDifferential === 4
            ? '>=' + situation.runDifferential
            : situation.runDifferential === -4
                ? '<=' + situation.runDifferential
                : situation.runDifferential;
        let inning = situation.inningOfEntry === 10
            ? '>=' + situation.inningOfEntry
            : situation.inningOfEntry;
        return ` (Run Diff ${runDiff}, Inning ${inning})`;
    } else if (tab === 'gameLog' && dateRange) {
        return ` (Between ${customDateString(dateRange.startDate)} and ${customDateString(dateRange.endDate)})`;
    }
    return '';
}