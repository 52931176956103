import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CalendarMonth from './CalendarMonth';
import DatePicker from '../Common/DatePicker';
import ExcelExporter from '../Common/ExcelExporter';
import TabulatorTable from '../Common/TabulatorTable';
import MultiSelect from '../Common/MultiSelect';
import { getBaserunnersDisplay } from '../Common/CommonFunctions';

function GameLog(props) {
    const [format, setFormat] = useState('calendar');
    const [statSelection, setStatSelection] = useState('1,2,3,4');
    const [statsToDisplay, setStatsToDisplay] = useState(null);

    const statSelectionList = {
        "1": { value: "1", label: 'IP', space: 3, dataFn: (game) => `${convertOutsToIP(game.FractionalInnings)} IP` },
        "2": { value: "2", label: 'Hld', space: 1, dataFn: (game) => game.Holds > 0 ? 'H' : '' },
        "3": { value: "3", label: 'Sv', space: 1, dataFn: (game) => game.Saves > 0 ? 'S' : '' },
        "4": { value: "4", label: 'gmLI', space: 6, dataFn: (game) => `${Number(game.gmLI).toFixed(2) } gmLI` },
        "5": { value: "5", label: 'SD', space: 2, dataFn: (game) => game.SD > 0 ? 'SD' : '' },
        "6": { value: "6", label: 'MD', space: 2, dataFn: (game) => game.MD > 0 ? 'MD' : '' },
        "7": { value: "7", label: 'W', space: 1, dataFn: (game) => game.W > 0 ? 'W' : '' },
        "8": { value: "8", label: 'L', space: 1, dataFn: (game) => game.L > 0 ? 'L' : '' },
        "9": { value: "9", label: 'Situation', space: 6, dataFn: (game) => getSituationDisplay(game) },
        "10": { value: "10", label: 'Season IP', space: 6, dataFn: (game) => `IP: ${convertOutsToIP(game.seasonToDate.FractionalInnings)}` },
        "11": { value: "11", label: 'Season Hld', space: 3, dataFn: (game) => `H: ${game.seasonToDate.Holds}` },
        "12": { value: "12", label: 'Season Sv', space: 3, dataFn: (game) => `S: ${game.seasonToDate.Saves}` },
        "13": { value: "13", label: 'Season gmLI', space: 6, dataFn: (game) => `gmLI: ${Number(game.seasonToDate.gmLI).toFixed(2) }` },
        "14": { value: "14", label: 'Season SD', space: 4, dataFn: (game) => `SD: ${game.seasonToDate.SD}` },
        "15": { value: "15", label: 'Season MD', space: 4, dataFn: (game) => `MD: ${game.seasonToDate.MD}` },
        "16": { value: "16", label: 'Season W', space: 3, dataFn: (game) => `W: ${game.seasonToDate.W}` },
        "17": { value: "17", label: 'Season L', space: 3, dataFn: (game) => `L: ${game.seasonToDate.L}` },
    }

    useEffect(() => {
        var curSpaceUsed = 0;
        let statsDisplay = [];
        let statLine = [];
        statSelection?.split(',').map(stat => statSelectionList[stat]).forEach((stat, index) => {
            if (curSpaceUsed + stat.space > 6) {
                statsDisplay.push(statLine);
                statLine = [];
                curSpaceUsed = 0;
            }
            statLine.push(stat.dataFn)
            curSpaceUsed += stat.space;
            if (index === statSelection?.split(',').length - 1)
                statsDisplay.push(statLine);
        }, [])

        setStatsToDisplay(statsDisplay);
    }, [statSelection])
    function convertOutsToIP(outs) {
        return Math.floor(outs / 3).toString() + '.' + (outs % 3).toString();
    }

    function getSituationDisplay(game) {
        let runDiffDisplay = game['Run Differential Before'].toString();
        if (runDiffDisplay.includes('-'))
            runDiffDisplay = runDiffDisplay.replace('-', 'b');
        else if (runDiffDisplay.includes('0'))
            runDiffDisplay = 't';
        else
            runDiffDisplay = 'a' + runDiffDisplay;

        runDiffDisplay = runDiffDisplay.replace('4', '≥4')
            
        return `${game.Inning}th ${runDiffDisplay}`
            + ` ${getBaserunnersDisplay(game['Baserunners Before'])} ${game['Outs Before']}`;
    }
    // need to group by two to make sure a pdfChunk isn't too big to fit in pdf page
    return (
        <>
            <div className='pdfChunk'>
                <br />
                <Row>
                    <h3>Game Log</h3>
                    <b className='label' style={{ paddingLeft: '25px', paddingRight: '25px' }}>From: </b>
                    <DatePicker
                        maxDate={props.dateRange.endDate}
                        minDate={new Date(`1/1/${props.dateRange.startDate.getFullYear()}`)}
                        setDate={(date) => props.updateDateRange(date, 'start')}
                        startDate={props.dateRange.startDate}
                    />
                    <b className='label' style={{ padding: '0px 25px' }}>To: </b>
                    <DatePicker
                        endDate={props.dateRange.endDate}
                        maxDate={new Date(`12/31/${props.dateRange.startDate.getFullYear()}`)}
                        minDate={props.dateRange.startDate}
                        setDate={(date) => props.updateDateRange(date, 'end')}
                    />
                    <b className='label' style={{ paddingLeft: '25px' }}>Format: </b>
                    <Form>
                        <div key={'custom-inline-radio'}>
                            <Form.Check
                                defaultChecked
                                custom
                                inline
                                name='format'
                                label='Calendar View'
                                key={1}
                                type='radio'
                                id='custom-inline-radio-calendar'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setFormat('calendar')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='format'
                                label='Table View'
                                key={2}
                                type='radio'
                                id='custom-inline-radio-table'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setFormat('table')
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='format'
                                label='Season To Date Table'
                                key={2}
                                type='radio'
                                id='custom-inline-radio-season-to-date'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setFormat('seasonToDate')
                                    }
                                }}
                            />
                        </div>
                    </Form>
                </Row>
            </div>
            {
                format === 'calendar' &&
                <div className='pdfIgnore pdfChunk'>
                    <br />
                    <MultiSelect
                        list={[
                            { value: "1", label: 'IP', space: 3, dataFn: (game) => `${convertOutsToIP(game.FractionalInnings)} IP` },
                            { value: "2", label: 'Hld', space: 1, dataFn: (game) => game.Holds > 1 ? 'H' : '' },
                            { value: "3", label: 'Sv', space: 1, dataFn: (game) => game.Saves > 1 ? 'S' : '' },
                            { value: "4", label: 'gmLI', space: 6, dataFn: (game) => `gmLI ${game.gmLI}` },
                            { value: "5", label: 'SD', space: 2, dataFn: (game) => game.SD > 1 ? 'SD' : '' },
                            { value: "6", label: 'MD', space: 2, dataFn: (game) => game.MD > 1 ? 'MD' : '' },
                            { value: "7", label: 'W', space: 1, dataFn: (game) => game.W > 1 ? 'W' : '' },
                            { value: "8", label: 'L', space: 1, dataFn: (game) => game.L > 1 ? 'L' : '' },
                            { value: "9", label: 'Situation', space: 6, dataFn: (game) => game['Outs Before'] },
                            { value: "10", label: 'Season IP', space: 6, dataFn: (game) => `${convertOutsToIP(game.seasonToDate.FractionalInnings)} IP` },
                            { value: "11", label: 'Season Hld', space: 3, dataFn: (game) => `H: ${game.seasonToDate.Holds}` },
                            { value: "12", label: 'Season Sv', space: 3, dataFn: (game) => `S: ${game.seasonToDate.Saves}` },
                            { value: "13", label: 'Season gmLI', space: 6, dataFn: (game) => `gmLI: ${game.seasonToDate.gmLI}` },
                            { value: "14", label: 'Season SD', space: 4, dataFn: (game) => `SD: ${game.seasonToDate.SD}` },
                            { value: "15", label: 'Season MD', space: 4, dataFn: (game) => `MD: ${game.seasonToDate.MD}` },
                            { value: "16", label: 'Season W', space: 3, dataFn: (game) => `W: ${game.seasonToDate.W}` },
                            { value: "17", label: 'Season L', space: 3, dataFn: (game) => `L: ${game.seasonToDate.L}` },
                        ]}
                        label={'Stats to Display'}
                        selectedItems={statSelection?.split(',') || []}
                        setSelectedItems={(selectedValues) => setStatSelection(selectedValues.length > 0 ? selectedValues.join(',') : null)}
                    />
                    <br />
                </div>
            }
            {
                format === 'calendar' &&
                [[2, 3], [4, 5], [6, 7], [8, 9]].map((monthGroup, monthGroupIndex) => {
                    return <Row className='pdfChunk' key={monthGroupIndex}>
                        {
                            monthGroup.map((month, monthIndex) => {
                                return <Col xs={12} xl={6}
                                    key={monthIndex}
                                >
                                    <CalendarMonth
                                        dateRange={props.dateRange}
                                        games={props.gameLogData}
                                        month={month}
                                        updateDateRange={props.updateDateRange}
                                        year={props.dateRange.startDate.getFullYear()}
                                        statsToDisplay={statsToDisplay}
                                    />
                                </Col>
                            })
                        }
                    </Row>
                })
            }
            {
                format === 'table' &&
                <div
                    className='pdfChunk tableChunk'
                    style={{
                        width: props.columns
                            ? (props.columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                            : '100%'
                }}>
                    <TabulatorTable
                        cols={props.columns}
                        data={
                            Object.values(props.gameLogData)
                                .flatMap(row => row)
                                .filter(game => game.Inning > 0
                                    && game.GameDate >= props.dateRange.startDate
                                    && game.GameDate <= props.dateRange.endDate)
                        }
                        initSort={[{ column: 'GameDateString', dir: 'asc' }]}
                        selectableRows={false}
                    />
                </div>
            }
            {
                format === 'seasonToDate' &&
                <div
                    className='pdfChunk tableChunk'
                    style={{
                        width: props.columns
                            ? (props.columns.slice(0, 22).reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                            : '100%'
                }}>
                    <TabulatorTable
                        cols={props.columns.slice(0, 22)}
                        data={
                            Object.values(props.gameLogData)
                                .flatMap(row => row)
                                .filter(game => game.Inning > 0
                                    && game.GameDate >= props.dateRange.startDate
                                    && game.GameDate <= props.dateRange.endDate)
                                .map(row => row.seasonToDate)
                        }
                        initSort={[{ column: 'GameDateString', dir: 'asc' }]}
                        selectableRows={false}
                    />
                </div>
            }
        </>
    )
}

export default GameLog;